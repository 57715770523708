<template>
<div >
    <table style="border-bottom: solid 1px grey; margin-bottom: 15px; margin-top: 15px; width: 100%;">
        <tr style="text-align: center">
            <td>
                <img height="150" :src="$store.state.userData.office.logoUrl" />
                <h2>{{$store.state.userData.office.name}}</h2>
            </td>
            <td style="text-align: left">
                <table id="orders">
                    <tr>
                        <td style="text-align: left">
                            <h3>UnPaid Orders</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>TOTAL ORDERS: <span class="primary--text">( {{data.length}} )</span></h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>TOTAL AMOUNTS: <span class="primary--text">( {{$service.formatCurrency(data.map(item => item.totalPriceAfterDiscount).reduce((prev, next) => prev + next))}} )</span></h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>TOTAL REMAINING: <span class="primary--text">( {{$service.formatCurrency(data.map(item => item.totalPriceAfterDiscount).reduce((prev, next) => prev + next) - data.map(item => item.amountReceived).reduce((prev, next) => prev + next) - data.map(item => item.cashDiscount).reduce((prev, next) => prev + next) )}} )</span></h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>FROM: <span class="primary--text">( {{filter.from}} )</span> TO: <span class="primary--text">( {{filter.to}} )</span></h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>CUSTOMER: <span class="primary--text">( {{filter.pharmacyId.fullName}} )</span></h3>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <br />
    </table>

    <table id="orders">
        <tr>
            <th>Order NO</th>
            <th>Created Date</th>
            <th>{{this.$route.meta.type}}</th>
            <th>Total Price After Discount</th>
            <th>Cash Discount</th> 
            <th>Recived Amount</th>
            <th>Remaining Amount</th>
        </tr>
        <div v-for="(item,i) in data" :key="i">
            <tr>
                
                <td rowspan="2" align="center">{{item.orderNO}}</td>
                <td>{{item.created.substr(0,10)}}</td>
                <td v-if="$route.meta.type == 'Pharmacy'">{{item.pharmacy.fullName}}</td>
                <td v-if="$route.meta.type == 'Repository'">{{item.repository.fullName}}</td>
                <td v-if="$route.meta.type == 'Doctor'">{{item.doctor.fullName}}</td>
                <td>{{$service.formatCurrency(item.totalPriceAfterDiscount)}}</td>
                <td>{{$service.formatCurrency(item.cashDiscount)}}</td>
                <td>{{$service.formatCurrency(item.amountReceived)}}</td>
                <td>{{$service.formatCurrency(item.totalPriceAfterDiscount - item.amountReceived - item.cashDiscount)}}</td>
            </tr>
            <tr>
                <td colspan="6">
                    <v-chip class="mr-1" label v-for="(p,index) in item.products" :key="index">
                        ( {{p.product.tradeName}} )
                    </v-chip>
                </td>
            </tr>
        </div>
        <!-- <tr v-for="(item,i) in data" :key="i">
            <td>{{item.orderNO}}</td>
            <td>{{item.created.substr(0,10)}}</td>
            <td v-if="$route.meta.type == 'Pharmacy'">{{item.pharmacy.fullName}}</td>
            <td v-if="$route.meta.type == 'Repository'">{{item.repository.fullName}}</td>
            <td v-if="$route.meta.type == 'Doctor'">{{item.doctor.fullName}}</td>
            <td>{{$service.formatCurrency(item.totalPriceAfterDiscount)}}</td>
            <td>{{$service.formatCurrency(item.cashDiscount)}}</td>
            <td>{{$service.formatCurrency(item.amountReceived)}}</td>
            <td>{{$service.formatCurrency(item.totalPriceAfterDiscount - item.amountReceived - item.cashDiscount)}}</td>

        </tr> -->

    </table>
</div>
</template>

<script>
export default {
    props: ['data', 'filter']
}
</script>

<style>
#orders {
    border-collapse: collapse;
    width: 100%;
}

#orders td,
#orders th {
    border: 1px solid #ddd;
    padding: 8px;
}

#orders tr:nth-child(even) {
    background-color: #f2f2f2;
}

#orders tr:hover {
    background-color: #ddd;
}

#orders th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #202020;
    color: white;
}
</style>
